/* You can add global styles to this file, and also import other style files */

// app fonts
@import url('//fonts.googleapis.com/css?family=Lato:300,400,700|Raleway:700');

// dependencies
@import 'bootstrap/scss/bootstrap';
@import 'font-awesome/scss/variables';
$fa-font-path: '/assets/fonts';
@import 'font-awesome/scss/font-awesome';

@import 'scss/core/main.scss';
@import 'scss/transact-theme.scss';
@import 'directives/password-eye-trigger/password-trigger.directive.scss';

@import './app/styles/variables.scss';
@import './app/styles/mixins_and_functions.scss';
@import './app/styles/user.scss';
@import './app/styles/xsact-controls.scss';
@import './assets/icons/styles.scss';

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.body-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

app-root {
  position: relative;
  height: 100%;
}

// Set up a default font and some padding to provide breathing room
body {
  -font-smoothing: antialiased;
  -osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  font-size: $main-font-size;
}

p {
  font-weight: 400;
  letter-spacing: .01em;
  line-height: 1.25em;
  margin-bottom: 1em;
  margin-top: 1em;
}

a {
  cursor: pointer;
}

a,
a:link,
a:hover {
  color: map-get($navigation, 'link_text_color_active');
}
a:visited,
a:active {
  color: darken(map-get($navigation, 'link_text_color_active'), 10%);
}