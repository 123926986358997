//
// Push down into whatever components (e.g., Bootstrap).
//
::ng-deep {
  .alert {
    // background-color: green !important;
    .lead {
      margin-top: 0;
    }
  }
}
