// Sitewide shared styling for all forms.

// @import 'scss/core/variables';
::placeholder {
  color: #ddd;
}

// Buttons may *not* necessarily be INSIDE a form!
// ugh, damn Angular nesting
// .xct-buttons {
.xct-button {
  &.btn-primary {
    &:disabled {
      background-color: #aaa;
    }
    &:enabled {
      background-color: $btn-primary-bg;
      &:hover {
        background-color: darken($btn-primary-bg, 7%);
      }
    }
  }
  &.btn-link {
    font-size: 11px;
    color: #3fb5be;
    padding: 0;

    .fa {
      color: #000;

      &.fa-remove {
        color: red;
      }
    }

    &:hover {
      color: red;
      text-decoration: none;

      .fa {
        color: red;
      }
    }
  }
}

.xct-upload-button {
  display: block;
  margin-bottom: 0.56em;
  border-radius: 0;
  border: 0;
  // width: 100%;
  // max-width: 120px;
  min-width: 160px;
  color: white;
  background-color: $transact-bgreen;
  padding: 0.7em;
  font-size: 0.8em;

  &:hover {
    background-color: darken($transact-bgreen, 6%);
  }

  .fa {
    font-size: 1.25em;
    padding-right: 0.33em;
  }
}
