// ==========================================================================
//  This is an inert file and generates *zero* CSS.
//
//  This file is intended only for importing variables and
//  also SASS mixins & functions.
//
//  Reimporting this file multiple times might not be the most efficient
//  build, but it will not result in any redundant CSS.
// ==========================================================================

@mixin vertical-align-center($position: relative) {
  -transform: translateY(-50%);
  position: $position;
  top: 50%;
  transform: translateY(-50%);
}

@mixin oauth-sidebar-text() {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;

  .publisher-message {
    margin-top: 1.5rem;
    border-top: 1px solid map-get($general-colors, 'border__normal');
    padding-top: 1rem;

    > h4 {
      color: $xs-aqua;
    }
  }

  @include max-phone-width() {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }
}

@mixin transaction-table() {
  .transaction-image {
    img {
      width: 100px;
    }
  }

  .recipient {
    display: block;
    font-weight: 500;
    color: black;
  }

  .title {
    font-size: 1.1em;
    font-weight: 500;
    display: block;
    word-break: break-word;
  }

  .label--white {
    border: 1px solid lightgrey;
  }
}

@mixin vertical-align-bottom($position: relative) {
  // -transform: translateY(-100%);
  bottom: 1%;
  position: $position;
  // transform: translateY(-100%);
}

@mixin set-aspect-ratio($width, $height) {
  position: relative;
  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin round-image($size) {
  background-color: $dot-color;
  border-radius: 50%;
  display: inline-block;
  margin: 0;
  height: $size;
  width: $size;
}

@mixin img-max-dimensions($width, $height) {
  display: block;
  max-width: $width;
  max-height: $height;
  width: auto;
  height: auto;
}

@mixin box-shadow-new($value) {
  box-shadow: $value;
}

@mixin form-message($color: #ff6f59, $text-color: #ffffff) {
  background-color: $color;
  color: $text-color;
  display: block;
  padding: 6px 10px;
  font-weight: 100;

  a,
  a:link {
    color: inherit;
    text-decoration: underline;
  }
}

@mixin refund-modal() {
  .modal-header {
    .refund-id-number {
      font-size: 0.8em;
      color: #17a5ae;
    }
  }

  .modal-body {
    font-size: 1.15em;
    position: relative;
    overflow: hidden;

    .fa-question-circle {
      position: absolute;
      color: lightgrey;
      font-size: 26em;
      z-index: 0;
      opacity: 0.13;
      top: -110px;
      @include transform(rotate(25deg));
      right: -80px;
    }

    p {
      &.question {
        margin: 0;
      }
    }

    ul {
      list-style: none;
      margin: 10px 0;
      padding: 0;

      li {
        padding: 5px 0;
        display: flex;
        align-items: baseline;

        input {
          margin: 0 10px 0 0;
        }

        span {
          cursor: pointer;
        }
      }
    }
  }
}
