.xct-debug-text {
  color: map-get($sitewide-debug, 'text_color__normal');
  font-size: map-get($sitewide-debug, 'text_size');
}

.xct-debug-json {
  font-family: Courier;
  font-size: 7pt;
  white-space: pre;
}
