.xsact-control-group {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  text-align: left;
  /*box-shadow: 0 1px 2px rgba(0,0,0,0.1);*/
  /*padding: 30px;*/
  width: 98%;
  /*height: 210px;*/
  margin: 10px;
}
.xsact-control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 15px;
  line-height: 1.5;

  &.radio-inline {
    display: inline-block;
  }
}
.xsact-control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.xsact-control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #eae8e8;
  background: #fff;
}
.xsact-control--radio .xsact-control__indicator {
  border-radius: 50%;
}
.xsact-control:hover input ~ .xsact-control__indicator,
.xsact-control input:focus ~ .xsact-control__indicator {
  background: #ccc;
}
.xsact-control input:checked ~ .xsact-control__indicator {
  background: #3fb5be;
}
.xsact-control:hover input:not([disabled]):checked ~ .xsact-control__indicator,
.xsact-control input:checked:focus ~ .xsact-control__indicator {
  background: #0e647d;
}
.xsact-control input:disabled ~ .xsact-control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.xsact-control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.xsact-control input:checked ~ .xsact-control__indicator:after {
  display: block;
}
.xsact-control--checkbox .xsact-control__indicator:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.xsact-control--checkbox input:disabled ~ .xsact-control__indicator:after {
  border-color: #7b7b7b;
}
.xsact-control--radio .xsact-control__indicator:after {
  left: 6px;
  top: 6px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.xsact-control--radio input:disabled ~ .xsact-control__indicator:after {
  background: #7b7b7b;
}
.xsact-select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}
.xsact-select select {
  display: inline-block;
  font-size: 15px;
  width: 100%;
  cursor: pointer;
  height: 51px;
  padding: 10px 15px;
  outline: 0;
  border: 1px solid #eae8e8;
  border-radius: 2px;
  background: #fff;
  color: #000;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.xsact-select select::-ms-expand {
  display: none;
}
.xsact-select select:hover,
.xsact-select select:focus {
  color: #000;
  background: #eee;
}
.xsact-select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.xsact-select__arrow {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 5.6px 5.55px 0 5.55px;
  border-color: #3fb5be transparent transparent transparent;
}
.xsact-select select:hover ~ .xsact-select__arrow,
.xsact-select select:focus ~ .xsact-select__arrow {
  border-top-color: #000;
}
.xsact-select select:disabled ~ .xsact-select__arrow {
  border-top-color: #ccc;
}