@import 'scss/core/variables.scss';

.password-eye {
  position: relative;

  i {
    &.fa-eye, &.fa-eye-slash {
      display: flex;
      font-family: "FontAwesome";
      font-size: 20px;
      justify-content: center;
      align-items: center;
      right: 1px;
      position: absolute;
      top: 1px;
      bottom: 1px;
      width: 40px;
      background: white;
      border-left: 1px solid rgba(0, 0, 0, .15);
      z-index: 1;
      cursor: pointer;
    }
  }
}