.xsact-icon-hamburger {
  .cls-1 {
    fill: #fff;
    fill-rule: evenodd;
  }
}

.xsact-icon-consumers_icon {
  .cls-1{fill:#cccccb;}
}

.xsact-icon-postd-logo {
  .cls-1{fill:#333;}
  .cls-2{fill:#6cc5e9;}
  .cls-3{fill:#842b42;}
  .cls-4{fill:#e12428;}
}

.xsact-icon-publishers_icon {
  .cls-1{fill:none;}
  .cls-2{fill:#cccccb;}
}

.xsact-icon-language {
  .cls-1,.cls-3{fill:#fff;}
  .cls-2,.cls-3{fill-rule:evenodd;}
}
