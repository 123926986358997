@import './styles/_Bootstrap_components';
// @import './styles/bootstrap_overrides';
@import './styles/_debugging';
@import './styles/_forms';

// .xct-table {}
.xct-table-row {
  display: table-row;
  background-color: orange;
  width: 100%;
  .xct-table-cell {
    display: table-cell;
  }
}


// Customize Bootstraps "panel," but only apply this if explicitly requested (i.e., "xct-panel-wrapper").
.xct-panel-wrapper {
  background-color: white;
  border: 1px solid #efeded;
  margin: 0;
  padding: 17px;

  // This accommodates Bootstrap setting form margin to -15px on left.
  &.xct-panel-form {
    .panel {
      .panel-heading {
        padding-left: 0;
      }
    }
  }

  .panel {
    // Kill Bootstrap's default shadow beneath a panel.
    @include box-shadow-new(none);
    margin-bottom: 0;
    border: 0;

    .panel-heading {
      background-color: inherit;
      // background-color: yellow;
      border: 0;
      padding-bottom: 7px;
      padding-left: 8px;

      > .panel-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        .xct-buttons {
          display: inline;
          margin-left: 1.5em;
        }
      }
    }

    .panel-body {
      // background-color: green;
      padding: 15px;
      padding-top: 5px;
    }
  }
}


// Classes to lock an image at a certain max width or height.
@for $i from 1 to 16 {
  $num: $i * 25;
  $num_px: $num * 1px;
  .xct-img-max-#{$num} {
    @include img-max-dimensions($num_px, $num_px);
  }
}
