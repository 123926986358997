@import './mixins_and_functions.scss';
@import './variables.scss';

:host ::ng-deep {
  .vert-margin {
    margin: 1.5em 0;
  }

  .large-spinner {
    font-size: 3em;
  }

  .large-number {
    color: $xs-gold;
    font-family: 'Lato';
    font-size: 3.4em;
    font-weight: 100;
    line-height: .8em;
    margin: 0;
  }

  .dashboard-content {
    .panel {
      border-radius: 0;
    }

    h5 {
      font-size: .86em;
      margin-top: 1.5em;
      text-transform: uppercase;
    }

    ul {
      font-size: 1.08em;
    }

    .has-error {

      .form-control {
        border-color: $brand-danger;
      }
    }

  }
  .checkbox-side-message {
    margin: .625em 0;

    a {
      color: $left-nav-link-color;
      font-weight: normal;
    }
  }
  .checkbox {
    label {
      padding-left: 2.5em;
    }

    [type='checkbox'] {
      height: 1.875em;
      margin-top: -0.1em;
      margin-left: -1.9em;
      width: 1.875em;
    }
  }

  .xct-publisher {
    background-color: map-get($general-colors, 'background_color');
    // background-color: orange;

    a.sidebar-toggle {
      color: green !important;

      &:hover {
        color: red !important;
      }
    }

    .xct-body-wrapper {
      // border: 2px solid #ddd;
      // border-radius: .4rem;
      width: 100%;
      height: 100%;
      // background-color: #fff;

    }

  }

  .xct-page-header {
    h1.ui.header {
      font-size: 1.2rem;
      color: #999;
      font-weight: normal;
      margin-bottom: .2em;
    }
  }

  .xct-page-row {
    border: 1px solid #eee;
    border-radius: .4rem;
    background-color: #fff;
    padding: 4em;

    h2.ui.header {
      font-size: .9em;
      color: #000;
      text-transform: uppercase;
    }
    .xct-edit-button {
      // background-color: yellow;
      // border: 1px solid blue;
      border: 0;
      padding-left: .5em;
      padding-right: .5em;
      padding-top: .08em;
      padding-bottom: .08em;
      font-size: .8em;
      .icon {
        margin-right: .12em !important; // SemDef .428571em
      }
    }
  }

  .ui {
    &.main.grid {
      left: 18.75%;
      position: relative;
      width: 81.25%;

      .ui.secondary.pointing.fluid.menu h2 {
        margin: calc(2rem - .14285em) 0 1rem;
      }
    }

    // This would better fit in sidebar Sass definitions, but Angular nests elements in unwanted ways
    // sometimes, and the menus must both be children of <body>. The the .menu tags must live here while
    // the content of the sidebars can live elsewhere. Must be a cleaner way.
    &.inverted.menu {
      background-color: map-get($navigation, 'background_color');
    }

    &.fixed.inverted.main.menu {
      display: none;
      // background-color: yellow;
    }

    &.left.fixed.vertical.inverted.menu {
      padding-left: 0;
      padding-right: 0;

      a.item {
        :first-child {
          padding-bottom: 30px;
          padding-top: 60px;
        }
      }
    }

    &.sidebar.inverted.vertical.menu a.item {
      :first-child {
        padding-bottom: 15px;
        padding-top: 30px;
      }
    }

    &.striped.table {
      padding-left: 0;
      padding-right: 0;
    }

    &.main.grid .products.column {
      padding: 1rem 0;

      .basic.segment {
        padding: 1rem 0;
      }
    }

    &.divided.product.items {
      width: 100%;

      img {
        height: 187px;
        width: 150px;
      }
    }

    &.mobile.cards {
      display: none;
    }
  }

  .dashboard.brand.icon {
    width: 100%;
    margin-bottom: 36px;
  }

  p {
    &.signup {
      padding-top: 25px;
      color: #999;
      text-align: center;
    }

    &.dashboard {
      font-size: 12px;
      padding-top: 10px;
      text-transform: uppercase;
      color: #999;
      text-align: center;
    }
  }

  header {
    h3 {

      a {
        color: $left-nav-link-color;
        font-weight: normal;
      }
    }
  }

  .xs-aqua {
    color: $xs-aqua;
  }

  .cancel-button {
    // width: 240px;
    // height: 50px;
    border: 0;
    background-color: #bababa;
    font-size: 1.3em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    margin-right: 15px;
    color: #fff;
  }

  .submit-button {
    width: 240px;
    height: 50px;
    border: 0;
    background-color: #3fb5be;
    font-size: 1.3em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #fff;
  }

  /* SMARTPHONE */
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .ui {
      &.mobile.cards {
        display: flex;
      }

      &.special.three.cards {
        display: none;
      }

      &.main.grid .row {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.special.three.cards .card .content {
        a.header,
        div.header {
          font-size: 1em;
        }
      }

      &.four.customer.statistics .statistic .value {
        font-size: 1.5em;
      }

      &.grid {
        .nine.wide.products.column {
          padding: 0;
          width: 100% !important;
        }

        .seven.wide.products.column {
          padding: 0;
          width: 100% !important;

          .ui.basic.right.aligned.segment {
            text-align: left;
          }
        }

        .four.wide.sales.column,
        .twelve.wide.sales.column,
        .ui.three.column.row .dashboard-stat.column {
          margin-bottom: 30px;
          width: 100% !important;
        }
      }

      &.header.datetime {
        margin: 3rem 0 1rem;
      }

      &.left.fixed.vertical.inverted.menu {
        display: none;
      }

      &.fixed.inverted.main.menu {
        display: block;
      }

      &.main.grid {
        left: 0;
        margin: 0 auto;
        padding: 2.75rem 0 0;
        position: relative;
        width: 100%;
      }
    }

    .doughnut-chart.column .ui.segment:last-child,
    .top-table.column .ui.segment:last-child {
      height: auto;
    }

    .doughnut-chart.column,
    .top-table.column {
      margin-bottom: 30px;
      width: 100% !important;
    }

  }

  /* TABLET */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .ui {
      &.header.datetime {
        margin: 3rem 0 1rem;
      }

      &.left.fixed.vertical.inverted.menu {
        display: none;
      }

      &.fixed.inverted.main.menu {
        display: block;
      }

      &.main.grid {
        left: 0;
        padding-top: 2.75rem !important;
        position: relative;
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .ui {
      &.fixed.inverted.main.menu {
        display: none;
      }

      &.left.fixed.vertical.inverted.menu {
        display: block;
      }
    }
  }
}
