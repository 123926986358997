// sass-lint:disable no-url-domains
@import url('//fonts.googleapis.com/css?family=Lato:300,400,700|Raleway:700');

@import 'sitewide_base';
// @import 'bootstrap_overrides';
@import 'xsact-controls';

* {
  margin: 0;
}
html,
body {
  height: 100%;
}
sd-app {
  position: relative;
  display: block;
  min-height: 100%;
}

// Set up a default font and some padding to provide breathing room
body {
  -font-smoothing: antialiased;
  -osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  font-size: $main-font-size;
}

p {
  font-weight: 400;
  letter-spacing: .01em;
  line-height: 1.25em;
  margin-bottom: 1em;
  margin-top: 1em;
}

a {
  cursor: pointer;
}
a,
a:link,
a:hover {
  color: map-get($navigation, 'link_text_color_active');
}
a:visited,
a:active {
  color: darken(map-get($navigation, 'link_text_color_active'), 10%);
}

.page-container {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
}

@media (max-width: $grid-float-breakpoint-max) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.form-group {
  .form-control {
    border-radius: 0;
  }

  button {
    margin-right: $container-padding;
  }
  .pull-right {
    button {
      margin-right: 0;
      margin-left: $container-padding;
    }
  }
}

.input-group-addon {
  border-radius: 0;
}

.dashboard-content {
  background-color: $dashboard-content;
  padding: $navbar-margin + $container-padding $container-padding $footer-margin $container-padding;
  height: 100%;
  min-height: 989px;

  .title-bar {
    margin-bottom: 1.43em;
    * {
      color: $xs-darkgrey;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.form-well {
  background-color: map-get($general-colors, 'background_color');
  border: solid 1px map-get($general-colors, 'border__normal');
  padding: 40px;
}

.user-form {
  label {
    font-size: 11pt;
    font-weight: normal;
    margin-bottom: 6px;
  }
  [type='text'],
  [type='number'],
  [type='email'],
  [type='password'] {
    font-size: 11pt;
    height: 50px;
    background-color: #f7f7f7;
    border: solid 1px #ced1d2;
    line-height: 1.71em;
  }

  [type='text'].inp-small,
  [type='email'].inp-small,
  [type='number'].inp-small,
  [type='password'].inp-small {
    height: 40px;
  }
}

.button-separation {
  padding-bottom: 1.375em;
  padding-top: 1.375em;
}

.button-spacer {

  button {
    margin-top: 20px;
  }
}

.vertical-center-align {
  @include vertical-align-center;
}

.bottom-align-text {
  @include vertical-align-bottom(absolute);
}

.success-message {
  @include form-message(#488e48);
}

nav.sidebar {
  li {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 14pt;

    a {
      color: #17a5ae;
    }
    &.active a {
      color: black;
      font-weight: 600;
    }
  }
}

.tooltip.xsact-tooltip {

  .tooltip-arrow {
    border-color: transparent;
    border-style: solid;
    height: 8px;
    margin-left: -8px;
  }

  .tooltip-inner {
    background-color: $brand-danger;
    border-radius: 0;
    font-size: 15px;
    line-height: 15px;
    color: white;
    padding: 8px;
    height: 31px;
    max-width: 250px;
  }
}

.tooltip.xsact-tooltip.bottom {

  margin-top: -15px;

  .tooltip-arrow {
    border-width: 0 8px 8px;
    border-bottom-color: $brand-danger;
    top: -3px;
    left: 90%;
  }

}

.tooltip.xsact-tooltip.top {

  margin-bottom: 15px;

  .tooltip-arrow {
    border-width: 8px 8px 0;
    border-top-color: $brand-danger;
    bottom: -3px;
    left: 80%;
  }

  .tooltip-inner {
    margin-left: -40px;
    margin-top: -10px;
  }

}


tooltip-container {
  text-align: right;
}

.has-error {

  .form-control {
    border-color: $brand-danger;
  }
}

.xsact-date-popups {

  .btn-info.active {
    background-color: $xs-aqua;
  }

  .popover {
    border-radius: 0;
  }

  .well-sm,
  .popover-content {
    border-radius: 0;
    margin-bottom: 1px;
    padding: 0;
  }

  .popover-title {
    color: #fff;
    background-color: $xs-aqua;
    border-radius: 0;
    text-align: center;
  }
}
